html {
  --antd-wave-shadow-color: transparent;
}

// +++++++++++++++++
#root {
  overflow-y: auto;
  overflow-x: hidden;
}
.gx-layout-sider-header .gx-site-logo {
  width: 150px;
}
.mobile-logo {
  width: 40px;
}
.gx-user-popover a {
  color: #ea5455;
}
.user-profile-name {
  display: flex;
  border-bottom: solid 1px #ffedee;
  background: #fff5f5 !important;
}
.user-profile .ant-popover {
  width: 100% !important;
  right: 0% !important;
  left: unset !important;
  transform-origin: unset !important;
  z-index: 99999;
  padding-top: 0 !important;
}
.ant-popover-inner-content {
  padding: 0px;
}
.ant-avatar > img {
  border-radius: 50%;
}
.gx-layout-sider-header {
  box-shadow: none;
  padding: 10px 0px 10px 0px;
}
.gx-layout-sider-header,
.ant-layout-header {
  height: 60px;
}
.ant-layout-header {
  flex-wrap: nowrap;
  padding-left: 20px;
}
.user-profile {
  position: relative;
}
.user-profile > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gx-avatar-name {
  white-space: nowrap;
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.gx-main-content-wrapper {
  padding: 15px 20px 0;
}
.gx-user-popover {
  margin: 0;
  padding: 0;
}
.user-profile-name span {
  margin-right: 10px;
}
.card-profile {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #ea54543a;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-profile .gx-user-popover li {
  padding: 10px 20px;
  width: 100%;
  align-items: center;
}
.profile-details label {
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 2px;
  font-weight: 400;
  display: inline-block;
}
.profile-option svg {
  color: #ea5455;
}
.profile-details h5 {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard-card {
  margin-bottom: 15px;
}
.profile-more-details {
  padding: 18px 25px;
  background: #ea545512;
  border-radius: 5px;
}
.profile-option {
  background: #fff;
  padding: 10px 5px;
  width: 100%;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
}
.profile-option h6 {
  font-size: 14px;
  margin: 0;
}
.profile-option span {
  font-size: 20px;
  margin-bottom: 5px;
  display: inline-block;
}
.ant-card-bordered {
  border: none;
}
.ant-card-body {
  border-radius: 10px;
  box-shadow: 0 0 2.1875rem rgba(90, 97, 105, 0.07),
    0 0 1.40625rem rgba(90, 97, 105, 0.07),
    0 0 0.53125rem rgba(90, 97, 105, 0.09),
    0 0 0.1875rem rgba(90, 97, 105, 0.07);
  padding: 20px 14px;
  position: relative;
  overflow: hidden;
}
.ant-collapse {
  background-color: #ea54550f;
  border: none;
}
.ant-collapse-expand-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}
.ant-collapse-content,
.ant-collapse > .ant-collapse-item {
  border: none;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  color: #ea5455;
  display: flex;
  font-size: 16px;
  font-weight: 400;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text
  span:last-child {
  margin-left: 5px;
}
.ant-collapse-content {
  background-color: transparent;
  border-top: 2px solid #fff;
}
.course-name {
  padding: 0 10px;
  background: #fff;
}
.course-name h5 {
  margin: 0;
  padding: 10px 0px 10px;
  font-size: 18px;
  border-bottom: 1px solid #e3e3e3;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: #ea5455;
}
.course-info {
  padding: 15px 0;
}
.course-detials {
  padding: 10px 15px;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-top: 15px;
}
.course-detials:last-child {
  margin-bottom: 0;
}
.course-detials h6 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}
.course-detials h4 {
  margin: 0;
  margin-top: -5px;
}
.ant-collapse.COMPLETED
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow,
.COMPLETED .ant-collapse-header-text {
  color: #3caf40 !important;
}
.ant-collapse.ONGOING
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow,
.ONGOING .ant-collapse-header-text {
  color: #d7b512 !important;
}
.ant-collapse.PENDING
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow,
.PENDING .ant-collapse-header-text {
  color: #d71212 !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.gx-table-responsive table {
  width: 100%;
  margin-top: 10px;
  padding: 10px 0;
}
.gx-table-responsive table td > table {
  margin-top: 0;
}
.course-detials table {
  background-color: #ea54550f;
}
.course-detials table tr td table {
  background: transparent;
}
.gx-table-responsive table th {
  padding: 10px;
  font-weight: 500;
  white-space: nowrap;
}
.gx-table-responsive table td {
  padding: 10px 0px;
  text-align: center;
}
.gx-table-responsive table td b {
  font-weight: 500;
}
.feedback {
  display: inline-block;
  height: 22px;
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  color: #fff;
}
.feedback.A {
  background: #4caf50;
}

.feedback.B {
  background: #03a9f4;
}
.feedback.C {
  background: #ff9800;
}
.feedback.D {
  background: #f44336;
}
.gx-table-responsive table tbody tr.main-topic {
  background: #ea545521 !important;
}

.gx-table-responsive table tbody tr:nth-of-type(odd) {
  background: #fce9e9b8;
}
.gx-table-responsive table tbody tr td table tr {
  background: transparent !important;
}
.gx-table-responsive table tbody tr td table,
.gx-table-responsive.CourseTable table tbody tr:not(.main-topic) {
  background: transparent !important;
  margin: 0;
}
.gx-table-responsive table tbody tr td table tr {
  padding: 0;
}
.gx-table-responsive table tbody tr td table tr {
  padding-top: 8px;
}
.gx-table-responsive table tbody tr td ul {
  margin: 0;
  padding: 0;
}
.gx-table-responsive table tbody tr td table tr:first-child {
  padding-top: 0;
}

.gx-table-responsive table td a {
  color: #ea5455;
  text-decoration: underline;
}
.gx-table-responsive table td span.paid {
  color: #61b563;
}
.gx-table-responsive table td span.unpaid {
  color: #ea5455;
}

.ant-card.student-details {
  margin-bottom: 10px;
}
.gx-table-responsive {
  overflow: auto;
}
.course-detials .ant-collapse {
  background-color: #fff;
  border: none;
}
.course-detials .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.course-detials .ant-collapse-header-text {
  position: relative;
}
.course-detials .ant-collapse-header-text:after,
.course-detials .ant-collapse-header-text:before {
  position: absolute;
  content: "";
  height: 7px;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  width: 7px;
  border-radius: 50%;
}
.course-detials .COMPLETED .ant-collapse-header-text:after,
.course-detials .COMPLETED .ant-collapse-header-text:before {
  background: #3caf40;
}
.course-detials .ONGOING .ant-collapse-header-text:after,
.course-detials .ONGOING .ant-collapse-header-text:before {
  background: #d7b512;
}

.course-detials .PENDING .ant-collapse-header-text:after,
.course-detials .PENDING .ant-collapse-header-text:before {
  background: #d71212;
}
.course-detials .ant-collapse-header-text:after {
  height: 11px;
  width: 11px;
  opacity: 0.2;
  right: 38px;
}

.login-box {
  padding: 15px 70px;
  background: #fff;
  // height: 400px;
  // width: 500px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2.1875rem rgba(90, 97, 105, 0.07),
    0 0 1.40625rem rgba(90, 97, 105, 0.07),
    0 0 0.53125rem rgba(90, 97, 105, 0.09),
    0 0 0.1875rem rgba(90, 97, 105, 0.07);
}

.ant-form-item-explain-error {
  font-size: 12px;
  text-align: center;
  margin: 3px 0;
}

.login-box {
  padding: 15px 70px;
  background: #fff;
  // height: 450px;
  // width: 500px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 120px;
  position: relative;
  box-shadow: 0 0 2.1875rem rgba(90, 97, 105, 0.07),
    0 0 1.40625rem rgba(90, 97, 105, 0.07),
    0 0 0.53125rem rgba(90, 97, 105, 0.09),
    0 0 0.1875rem rgba(90, 97, 105, 0.07);
}
.login-box span.login-pattern {
  top: -30%;
  position: absolute;
  height: 250px;
  border-radius: 50%;
  width: 110%;
  background: #ea545526;
}
.login-box .ant-form.ant-form-horizontal + button,
.login-box .ant-form.ant-form-horizontal {
  text-align: center;
  width: 320px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary {
  color: #fff;
  border-color: #ea5455 !important;
  background: #ea5455 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  color: #fefefe !important;
  background-color: #ea5455 !important;
  border-color: #ea5455 !important;
}
.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active {
  color: #fefefe;
  background-color: #ea5455 !important;
  border-color: #ea5455 !important;
}
.ant-input:hover {
  border-color: #ea5455;
  border-right-width: 1px;
}
#root:has(> .ant-row .ant-col .login-box) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-logo {
  width: 150px;
  position: absolute;
  bottom: 17%;
  left: 49%;
  transform: translateX(-50%);
}
.otp-input input {
  height: 50px;
  width: 50px !important;
  border-radius: 2px !important;
  margin: 0 4px;
  font-size: 18px;
  font-weight: 500;
  padding: 7.9px 3px;
}
.otp-input span.ant-input-group-compact {
  display: flex;
}
.login-box .ant-row {
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
}
.login-box .ant-form-item-label > label {
  height: auto;
  margin-bottom: 0px;
}
.login-box .ant-form-item-explain-error {
  margin-top: 5px;
}
.login-box .ant-form-horizontal .ant-form-item-control {
  width: 100%;
}
.login-box .ant-btn {
  width: 100%;
  margin-top: 10px !important;
}
.login-box .otp-submit {
  margin: 0 !important;
}
.change-number {
  color: #ea5455;
  cursor: pointer;
  display: block;
  text-align: center;
}
.gx-table-responsive table td p {
  margin-bottom: 0;
}
.topicFeedback {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.topicFeedback h4 p {
  font-size: 20px;
  margin-bottom: 8px;
  max-width: 100%;
  white-space: pre-wrap;
}
.topicFeedback h4 {
  width: 80%;
}
.topicFeedback span {
  font-size: 12px;
  margin-top: 9px;
  opacity: 0.8;
  display: inline-block;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content {
  display: flex;
  margin: 20px 0 0 15px;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label {
  height: 92px;
  width: 170px;
  position: relative;
  border-radius: 3px;
  font-weight: 400;
  font-size: 24px;
  border: 1px solid transparent;
  margin: 4px;  
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:first-child {
  background: #e7f3e8;
  color: #4caf50;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:nth-child(2) {
  background: #e3f1f7;
  color: #03a9f4;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:nth-child(3) {
  background: #f7efe4;
  color: #ff9800;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:last-child {
  background: #f7e2e1;
  color: #f44336;
  margin-right: 0;
}
#myForm #remarks {
  background-color: #fffbfb99;
  border-color: #ffcdcd !important;
  box-shadow: none !important;
  height: 43px;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:first-child.ant-radio-wrapper-checked {
  border: 1px solid #4caf50;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:nth-child(2).ant-radio-wrapper-checked {
  border: 1px solid #03a9f4;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:nth-child(3).ant-radio-wrapper-checked {
  border: 1px solid #ff9800;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label:last-child.ant-radio-wrapper-checked {
  border: 1px solid #ea5455;
}

.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label
  .ant-radio-inner {
  opacity: 0;
}
.mainTopicFeedback
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label
  span {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gx-table-responsive td {
  text-transform: capitalize;
}
.student-details-card {
  margin-bottom: 10px;
}
.username {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 575px) {
  .gx-table-responsive table td {
    white-space: nowrap;
  }
  .ant-radio-group {
    width: 100%;
    text-align: center;
  }
  .profile-details h5.usernameMobile + span {
    font-size: 14px;
    opacity: 0.8;
  }
  .gx-main-content-wrapper {
    padding: 0px 15px 0;
  }
  .profile-details.mobile-profile {
    background: #ea545512;
    margin: 15px 0 0;
    padding: 15px;
    border-radius: 10px;
  }
  .ant-card-body {
    padding: 15px;
  }
  .profile-more-details {
    padding: 18px 15px;
  }
  .course-name {
    padding: 0 0px;
  }
  .ant-card.student-details {
    margin-bottom: 10px;
  }
  .topicFeedback {
    flex-direction: column;
  }
  .mainTopicFeedback
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    label {
    height: 80px;
    width: 47%;
    font-size: 20px;
  }
  .topicFeedback h4 p {
    margin-bottom: 0;
  }
  .topicFeedback span {
    margin: 0;
  }

  .mainTopicFeedback
    .ant-form-item-control-input
    .ant-form-item-control-input-content {
    justify-content: center;
  }
  .profile-details h5.usernameMobile {
    font-size: 16px !important;
    font-weight: 400;
    margin-bottom: 5px;
  }
}

#confirm_help .ant-form-item-explain-error:not(:first-child),
#mpin_help .ant-form-item-explain-error:not(:first-child),
.login-box #otp_help .ant-form-item-explain-error:not(:first-child) {
  display: none;
}
#confirm_help .ant-form-item-explain-error:nth-child(2),
#mpin_help .ant-form-item-explain-error:nth-child(2) {
  display: block;
}
.card-profile .ant-avatar-image .ant-image-img,
.card-profile .ant-avatar-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.card-profile .ant-avatar-image .ant-image {
  height: 100%;
  width: 100%;
}
.avtar-default {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.avtar-default .anticon.anticon-user {
  font-size: 30px;
  color: #ea5455;
}

.ant-form-item-label > label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 10px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block;
  margin-right: -4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  position: absolute;
  top: -4px;
  left: unset;
  right: 16px;
}

.label.as-placeholder {
  top: 11px;
}
.no-found-page {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-found-page img {
  max-width: 50%;
  mix-blend-mode: multiply;
}
.no-found-page a {
  color: #ea5455;
}

@media (max-width: 575px) {
  .ant-card-body .card-profile {
    height: 80px !important;
    width: 80px !important;
  }
  .no-found-page img {
    max-width: 100%;
  }
  .ant-card-body .profile-details h5 {
    font-size: 14px;
    white-space: wrap;
    overflow: auto;
    text-overflow: unset;
  }

  .profile-details label {
    font-size: 12px;
  }
  .login-box .ant-form.ant-form-horizontal {
    padding: 60px 0px 60px 0px;
  }
  .login-box .ant-form.ant-form-horizontal {
    width: 280px;
    text-align: center;
  }
  .login-box {
    padding: 140px 30px 20px 30px;
    height: unset !important;
    width: unset !important;
  }
  .otp-number {
    height: 38px !important;
  }
}

@media (min-width: 576px) and(max-width:768px) {
  .ant-card-body .profile-details h5 {
    font-size: 16px;
    font-weight: 500;
    color: #404040;
    width: 100%;
    white-space: wrap;
    overflow: auto;
    text-overflow: unset;
  }
  .no-found-page img {
    max-width: 100%;
  }
  .login-box {
    padding: 100px 40px 0px 40px;
    height: unset !important;
    width: unset !important;
  }
}
.login-box .mobileEnter {
  padding: 70px 0px 70px 0px;
}
.login-box {
  height: 430px;
  width: 400px;
}
.ant-popover-inner-content {
  width: 100%;
}
.login-box p {
  margin: 15px 0px;
}

.login-box h1 {
  margin: 0;
}
.backBtn {
  display: inline-block;
  background: #ea5455;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
  margin-right: 15px;
}
.ant-collapse-content:has(> .ant-collapse-content-box:empty) {
  display: none;
}
.course-detials .undefined .ant-collapse-expand-icon {
  display: none;
}
.course-detials .ant-collapse-expand-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media (max-width: 340px) {
  .login-box .ant-form.ant-form-horizontal {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .login-box .ant-form-item,
  .login-box .ant-form-item-with-help {
    margin-bottom: 4px;
  }
}
.login-box .ant-btn {
  width: 100%;
  margin-top: 0px !important;
}

.login-box .ant-btn:nth-child(2) {
  margin-top: 10px !important;
}
.login-box .ant-btn:nth-child(3) {
  margin-top: 13px !important;
}
.login-box .ant-form-item {
  margin-bottom: 10px;
}
@media screen and (max-width: 475px) {
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-bottom: 0px;
  }
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
}

@media (max-width: 991px) {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    font-size: 13px;
  }
  .ant-layout-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  .ant-layout.gx-app-layout > .ant-layout,
  .ant-layout.gx-app-layout,
  #root {
    overflow-y: unset;
    height: auto;
  }
  #root:has(> .ant-row .ant-col .login-box) {
    height: 100vh;
  }
  .ant-layout > .gx-layout-content {
    padding: 80px 0 10px;
  }
  body {
    overflow: hidden auto;
  }
  .course-detials .ant-collapse-header-text {
    max-width: 85%;
  }
  .course-detials .ant-collapse-header-text:after,
  .course-detials .ant-collapse-header-text:before {
    right: -10px;
  }
  .course-detials .ant-collapse-header-text:after {
    right: -12px;
  }

  .gx-table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  .gx-table-responsive::-webkit-scrollbar {
    width: 3px;
    height: 2px;
    background-color: #f5f5f5;
  }

  .gx-table-responsive::-webkit-scrollbar-thumb {
    background-color: #ea5455;
  }
  .gx-table-responsive table th,
  .gx-table-responsive table td {
    font-size: 12px;
  }
  .mainTopicFeedback
    .ant-form-item-control-input
    .ant-form-item-control-input-content {
    display: flex;
    margin: 20px 0 0 0px;
  }
}
.no-data {
  height: 150px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
}
.no-data svg {
  font-size: 40px;
  opacity: 0.5;
  margin-bottom: 5px;
}
.ant-btn-ghost {
  color: #ea5455;
  border-color: #ea5455;
  background: transparent;
}
.ant-btn-ghost:hover {
  background: #ea5455;
  color: #fff;
  border-color: #ea5455;
}
.updateMPIN .otp-number {
  text-align: center;
}
.updateMPIN .ant-form-item > .ant-row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.updateMPIN .ant-form-item > .ant-row label {
  justify-content: center;
}
.updateMPIN .ant-form-item-label > label::after {
  display: none;
}
.ant-form-item-label > label {
  height: auto;
  margin-bottom: 0px;
}
#confirm_help .ant-form-item-explain-error .notSameMPIN {
  display: none;
}
#confirm_help .ant-form-item-explain-error:first-child .notSameMPIN {
  display: block;
}
.updateMPIN .ant-form-item-label > label {
  padding-right: 5px;
}
.updateMPIN
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin: 0;
  right: 0px;
}
.feedback.null {
  display: none;
}
.absent-data {
  background: #ea5455;
  padding: 2px 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
}
.student-Submission{
  cursor:pointer;
}
.feedback-mark {
  padding: 5px 10px;
  background: #dfdfdf;
  border-radius: 6px;
}
.feedback-value {
  position: relative;
}
.feedback-value .feedback-values {
  position: absolute !important;
  bottom: -22px !important;
  top: initial !important;
  font-size: 12px;
  text-wrap: nowrap;
}
.feedback-A{color: #4caf50;}
.feedback-B{color: #03a9f4;}
.feedback-C{color: #ff9800;}
.feedback-D{color: #f44336;}

.submit-btn:disabled{
  cursor: no-drop;
  opacity: 0.4;
}
.faculty-dashboard-card .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .faculty-dashboard-card .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder{
  line-height:39px !important ;
}
.reload-modal .ant-modal-body{padding: 17px;}
.alert-success{
  color: rgb(84, 202, 104)  ;
}
.alert-pending{
  color: #ffa426;
}
.alert-ongoing{
  color: #ea5455;
}

.reload-modal{
  width: 400px !important;
}
.reload-modal .ant-btn-default{
  background-color: #ea5455 !important;
  color: #fff!important;
}
.reload-modal .ant-modal-body{text-align: center}
.date-formate{
  padding:10px 10px !important;
}
.feedbackmark{
  padding:0px 10px !important;

}
@media(max-width:576px){
  .login-box span.login-pattern {
    top: -18%;
}
// .login-logo {
//   bottom: 29%;
// }
.login-box .ant-form-item{
  width: 320px;
}
.login-box .ant-btn:nth-child(2) {
  width: 320px;
}
.login-box {
  padding: 203px 30px 20px 30px !important;
}
.login-box .mobileEnter {
  padding: 20px 0px;
}

.login-box {
    padding: 125px 30px 20px 30px !important;
}
}

.feedback-C {
  margin-right: 58px;
}
.gx-table-responsive table td {
  padding: 10px 16px;
  text-align: center;
}
@media(max-width:576px){
  .login-box .ant-form-item-label > label.ant-form-item-required:not( .ant-form-item-required-mark-optional )::before{
    right: -3px;
  }
  .login-box .ant-form-item .ant-form-item-label{
    padding-right: 20px !important;
  }
  .login-box .ant-form-horizontal .ant-form-item-control {
    width: 280px;
}
  .login-box .ant-btn:nth-child(2) {
    width: 280px;
}
.login-box .ant-form.ant-form-horizontal + button, .login-box .ant-form.ant-form-horizontal {
  text-align: center;
  width: 280px;
}
.login-box .ant-form.ant-form-horizontal {
  padding: 60px 0px 0px 0px;
}
.login-box .ant-form-horizontal .ant-form-item-control {
  width: 280px !important;
}
}
.feedback-student{
  flex-wrap: wrap;
}
.ant-btn:hover, .ant-btn:focus {
  color: #ea5455 ;
  border-color: #ea5455 ;
  background: #fff;
}
.ant-btn{
  color: #ea5455 ;
  border-color: #ea5455 ;
  background: #fff;
}
.close-button-modal .ant-modal-close-x{
  position: relative;
  top: 11px;
  left: -10px;
}

.more-text button {
  border: none;
  padding: 0;
  font-size: 11px;
  height:16px ;
  line-height: 1;
  padding: 3px 8px !important;
  cursor: pointer;
  margin: 0 !important;
  border-radius: 5px;
  background-color: #f44336;
  color: white;
}

.remark-message {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #7e7e7e;
}
.more-text .ant-btn:hover, .more-text .ant-btn:focus {
  color: #f44336 !important;
  border-color: #28aaeb;
  background: #fff;
}
.more-button-modal .ant-modal-confirm-info .ant-modal-confirm-body > .anticon{
  color: #ea5455;
}
.more-button-modal .ant-modal-content{
  text-align: center ;
}
.more-button-modal.ant-modal-confirm .ant-modal-confirm-btns{
  text-align: center !important;
}

.note-text{
  white-space: nowrap; 
  max-width: 150px; 
  overflow: hidden;
  text-overflow: clip; 
}

/*----------- BUTTON-CSS -----------*/
.ant-btn-primary.disabled,
.ant-btn-primary:disabled,
.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active,
.ant-btn-primary {
  background: #ea5455 !important;
  color: #fff !important;
  background-color: #ea5455 !important;
  border-color: #ea5455 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: white !important;
  color: #ea5455 !important;
  border-color: #ea5455 !important;
}
/*----------- BUTTON-CSS -----------*/



/*----------- EXAM-PORTAL-CSS -----------*/
.exam-user-info-card{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.exam-user-info-card .exam-card{
  max-width: 750px;
  width: 100%;
}

.exam-card-details{
  margin: 10px 0 15px 0;
}

.exam-card-details .card-detail-title{
  text-align: center;
}

.exam-rules{
  color: red;
  display: block;
  margin-bottom: 5px;
}

.rules-line{
  color: gray;
  margin-bottom: 5px !important;
}

//Dashboard css

.custom-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width : 13% !important;
  background: "#fff"
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.custom-scrollbar .ant-menu{
  border-right: 1px solid #e8e8e8 !important;
}

.circle-indicator {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  border: 2px solid #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.custom-scrollbar .ant-menu-inline .ant-menu-item::after {
  border-right: 1px solid #ea5455 !important;
  background: #ea5455 !important;
}

.custom-scrollbar .ant-menu-item-selected {
  color: #ea5455 !important;
  background: #ea545521 !important;

}

.custom-scrollbar .ant-menu-light .ant-menu-item:hover{
  color: #ea5455 !important;
  background: #ea545521 !important;
}

.circle-indicator.selected {
  border-color: #535353;
}

.exam-dashboard-layout {
   height: 100vh
}

.exam-dashboard-layout .exam-menu{
  height: 100%;
  border-right: 0px;
}

.exam-dashboard-layout .exam-content{
  margin: 0;
  padding: 24px;
  min-height: 280px;
}

.exam-dashboard-layout .exam-options {
  display: block;
  margin-bottom: 8px;
}

.exam-dashboard-layout .exam-buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.exam-dashboard-layout .exam-sider {
  background: #fff;
  padding: 24px;
  border-left: 1px solid #e8e8e8;
}

.exam-dashboard-layout .exam-countdown {
  text-align: center;
}

.exam-dashboard-layout .exam-submit-btn {
  display: block;
  width: 100%;
  margin-top: 24px;
}

.thank-you-card{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.error-message{
  color: red;
}

.ant-modal-title {
  text-align: center;
}

.exam-dashboard-layout .exam-buttons .ant-btn-primary:disabled{
  margin-top: 5px;
}

.action-icon > div {
  padding: 5px 0;
  text-align: center;
  margin: 0;
  line-height: 1;
  width: 25px;
  border-radius: 5px;
  font-size: 14px;
  margin: 5px auto;
  background: #ea5455;
}

.answersheet-modal {
  overflow-y: auto;
  max-height: 715px;
}

.answersheet-modal::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.answersheet-modal::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

.answersheet-modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ea5455;
}

table.faculty-signing .ant-empty-normal {
  height: auto;
}

.faculty-signing-table .ant-card-body {
  overflow-x: auto;
}

.faculty-signing {
  width: 100%;
}

.faculty-signing thead tr th {
  padding: 8px 10px;
  font-weight: 500;
  position: sticky;
  top: 1px;
  color: #fff;
  background: #ea5455;
  z-index: 99;
}

.faculty-signing thead tr th:before {
  background-color: hsla(0, 0%, 100%, 0.5);
  content: "";
  height: 1.6em;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  width: 1px;
}

.faculty-signing thead tr th:last-child:before {
  display: none;
}

.faculty-signing tbody tr td {
  border: 1px solid #e1e1e1;
  padding: 8px 10px;
  font-weight: 500;
}

.faculty-signing tbody tr.sub-topic td {
  opacity: 0.9;
  font-weight: 400;
}

.faculty-signing tbody tr td:has(td) {
  padding: 0;
}

.faculty-signing tbody tr td td {
  border-top: none;
  border-bottom: none;
  border-left: none;
}

.faculty-signing tbody tr td td:last-child {
  border-right: 0;
}

.faculty-signing-th {
  height: 1.6em;
  padding: 8px 8px;
  font-weight: 500;
  border-top: none;
  border-bottom: none;
  border-left: none;
}

.faculty-signing-th:nth-child(3n + 1) {
  border-right: 1px solid #e1e1e1;
}

.faculty-signing-th:not(:nth-child(3n)) {
  border-right: 1px solid #e1e1e1;
}

.faculty-signing tbody tr td.sub-table {
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  border: none;
  height: 34px;
  line-height: 19px;
  padding: 8px 40px;
}

.faculty-signing tbody tr td:has(> .sheet-data:empty) {
  border: none;
}

.faculty-signing-table .ant-card-body {
  overflow: hidden;
}

.faculty-signing-table .ant-card-body > div {
  max-height: calc(100vh - 230px);
  overflow: auto;
}

@media (max-width: 575px) {
  .faculty-signing-table .ant-card-body {
    max-height: calc(100vh - 220px);
  }
  .faculty-signing-table .ant-card-body > div {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .faculty-signing-table .ant-card-body {
    max-height: calc(100vh - 187px);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .faculty-signing-table .ant-card-body > div {
    max-height: calc(100vh - 238px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faculty-signing-table .ant-card-body > div {
    max-height: calc(100vh - 224px);
  }
}

.faculty-signing tbody tr.sub-topics td {
  opacity: 0.9;
  font-weight: 400;
}
.faculty-signing-table {
  height: calc(100vh - 240px);
}

.faculty-signing.second-view-question .ant-empty-normal {
  max-height: calc(100vh - 370px) !important;
  min-height: calc(100vh - 370px) !important;
}

.faculty-signing.exam-list .ant-empty-normal {
  max-height: calc(100vh - 320px) !important;
  min-height: calc(100vh - 390px) !important;
}
.correct-answer {
  color: rgb(84, 202, 104);
}